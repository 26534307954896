import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Cards from "../components/knowledgeCentre/Cards";
import Layout from "../components/Layout";
import KnowledgeCentrePage from "../templates/KnowledgeCentrePage";

const seo = {
  title: "Technical articles",
  description:
    "Our regular series of technical articles is all about clarifying some of the most commonly held misconceptions in the field of materials mechanics and mechanical testing.",
};

function TechnicalArticlesPage() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/technicalArticles/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              description
              pdf {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return (
    <KnowledgeCentrePage seo={seo} linkPath="/technical-articles">
      <Cards
        title={"Technical Articles"}
        byline={
          "Our regular series of technical articles is all about clarifying some of the most commonly held misconceptions in the field of materials mechanics and mechanical testing."
        }
        data={data}
      />
    </KnowledgeCentrePage>
  );
}

export default TechnicalArticlesPage;
